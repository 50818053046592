import * as builder from './vendors/builder.js';
import Land from "./components/land.js";
import './vendors/fa.js'
import '../styles/builder.css'
import '../styles/desktop.css'
import '../styles/tablets.css'
import '../styles/phones.css'
import('../styles/Remix/remixicon.scss')

builder.entrypoit(()=>{
    const land = new Land();

    builder.app.append(land.getHTML())
})