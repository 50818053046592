import * as builder from "../vendors/builder.js"
import Header from "./header.js";
import Benefits from "./benefits.js";
import MoreBenefits from "./moreBenefits.js";

export default class Land extends builder.Component
{
    constructor() {
        super();
        this.create()
    }

    create() {
        let header = new Header(this),
            benefits = new Benefits(),
            More = new MoreBenefits();

        this.component = builder.block(null, 'vicles_land', [header.getHTML(), benefits.getHTML(), More.getHTML()])
    }
}