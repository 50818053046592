import * as builder from '../vendors/builder.js';
import translate from '../../translate/landing.json' with { type: "json" };

export default class Benefits extends builder.Component
{
    constructor() {
        super();
        this.create()
    }

    #create_benefit_card(icon, title, text)
    {
        let vbc_icon = builder.button(null, 'vicles_benefits_card_icon', null, icon),
            vbc_title = builder.label('vicles_benefits_card_title', title),
            vbc_text = builder.label('vicles_benefits_card_text', text);

        return builder.block(null, 'vicles_benefits_card', [vbc_icon, vbc_title, vbc_text])
    }

    create() {
        let question = builder.label('vicles_benefits', ''),
            benefits_dock = builder.block(null, 'vicles_benefits_dock', [
                this.#create_benefit_card('<i class="ri-group-line"></i>', translate.BenefitsUserManagementTitle[builder.prefs.get('language')], translate.BenefitsUserManagementText[builder.prefs.get('language')]),
                this.#create_benefit_card('<i class="ri-community-line"></i>', translate.BenefitsAgencyToAgencyTitle[builder.prefs.get('language')], translate.BenefitsAgencyToAgencyText[builder.prefs.get('language')]),
                this.#create_benefit_card('<i class="ri-pages-line"></i>', translate.BenefitsAgencyPageTitle[builder.prefs.get('language')], translate.BenefitsAgencyPageText[builder.prefs.get('language')]),
                this.#create_benefit_card('<i class="ri-advertisement-line"></i>', translate.BenefitsAdsTitle[builder.prefs.get('language')], translate.BenefitsAdsText[builder.prefs.get('language')])
            ]),
            words = translate.BenefitsQuestion[builder.prefs.get('language')].split(" "),
            length = Math.ceil(words.length / 2),
            black = words.slice(0, length).join(" "),
            orange = " "+words.slice(length).join(" ")

        if (builder.isArabic(translate.BenefitsQuestion[builder.prefs.get('language')]))
            question.style.direction = 'rtl';
        question.setAttribute("black", black)
        question.setAttribute("orange", orange)

        this.component = builder.block(null, 'vicles_benefits_container', [question, benefits_dock]);
    }
}