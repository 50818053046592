import * as builder from '../vendors/builder.js';
import Register from "./register.js";
import translate from '../../translate/landing.json' with { type: "json" };

export default class MoreBenefits extends builder.Component
{
    constructor() {
        super();
        this.create()
    }

    #hero(image, title, text, dir)
    {
        if (dir)
        {
            let vh_image = builder.image(null, 'vicles_hero_bottom_image_revert', image),
                vh_title = builder.label("vicles_hero_bottom_title", title),
                vh_text = builder.label("vicles_hero_bottom_text", text);
            return builder.block(null, 'vicles_hero_bottom_revert', [vh_image, vh_title, vh_text]);
        }

        let vh_image = builder.image(null, 'vicles_hero_bottom_image', image),
            vh_title = builder.label("vicles_hero_bottom_title", title),
            vh_text = builder.label("vicles_hero_bottom_text", text);

        return builder.block(null, 'vicles_hero_bottom', [vh_image, vh_title, vh_text]);
    }

    create() {
        let question = builder.label('vicles_benefits', ''),
            cloud = this.#hero('./assets/bring_to_cloud.webp', translate.cloudBenefitTitle[builder.prefs.get('language')], translate.cloudBenefitText[builder.prefs.get('language')]),
            fines = this.#hero('./assets/no_more_fines.webp', translate.noMoreFinesTitle[builder.prefs.get('language')], translate.noMoreFinesText[builder.prefs.get('language')], 1),
            more = this.#hero('./assets/more.webp', translate.moreRentalsTitle[builder.prefs.get('language')], translate.moreRentalsText[builder.prefs.get('language')]),
            footer_hook = builder.label("vicles_foooter_cta_hook", translate.FooterCTA[builder.prefs.get('language')]),
            footer_join = builder.button(null, 'vicles_foooter_cta_join', translate.joinUS[builder.prefs.get('language')], null),
            footer_cta_aligner = builder.block(null, 'vicles_footer_cta_aligner', [footer_hook, footer_join]),
            footer_cta_container = builder.block(null, 'vicles_footer_cta_conatiner', [footer_cta_aligner]),
            words = translate.MoreBenefits[builder.prefs.get('language')].split(" "),
            length = Math.ceil(words.length / 2),
            black = words.slice(0, length).join(" "),
            orange = " "+words.slice(length).join(" ")

        question.setAttribute("black", black)
        question.setAttribute("orange", orange)


        footer_join.onclick = ()=>{
            builder.app.append((new Register()).getHTML())
        }
        if (builder.isArabic(translate.MoreBenefits[builder.prefs.get('language')]))
            question.style.direction = 'rtl';

        this.component = builder.block(null, 'vicles_benefits_container_bottom', [question, cloud, fines, more, footer_cta_container]);
    }
}