import * as builder from '../vendors/builder.js';
import translate from '../../translate/landing.json' with { type: "json" };


export default class Register extends builder.Component
{
    constructor() {
        super();
        this.create()
    }

    create() {
        let icon = builder.button(null, 'vicles_register_icon', null, "<i class=\"ri-add-line\"></i>"),
            title = builder.label('vicles_register_title', translate.joinUS[builder.prefs.get('language')]),
            close = builder.button(null, 'vicles_register_close', null, "<i class=\"ri-close-circle-line\"></i>"),
            tools = builder.block(null, 'vicles_register_window_tools', [icon, title, close]),
            name = builder.textBox(null, translate.nameField[builder.prefs.get('language')],"text",'vicles_register_field'),
            email = builder.textBox(null, translate.emailField[builder.prefs.get('language')],"text",'vicles_register_field'),
            phone = builder.textBox(null, translate.phoneField[builder.prefs.get('language')],"text",'vicles_register_field'),
            fields = builder.block(null, 'vicles_register_fields', [name, email, phone]),
            submit = builder.button(null, 'vicles_register_submit', translate.join[builder.prefs.get('language')]),
            cancel = builder.button(null, 'vicles_register_cancel', translate.cancel[builder.prefs.get('language')]),
            buttons = builder.block(null, 'vicles_register_buttons', [cancel, submit]),
            window = builder.block(null, 'vicles_register_window', [tools, fields, buttons]),
            background = builder.block(null, 'vicles_register_background', []);

        background.onclick = ()=>{
            builder.app.removeChild(background.parentNode)
        }

        close.onclick = ()=>{
            builder.app.removeChild(background.parentNode)
        }

        cancel.onclick = ()=>{
            builder.app.removeChild(background.parentNode)
        }

        email.setAttribute("required", 'true')
        name.setAttribute("required", 'true')
        phone.setAttribute("required", 'true')

        email.setAttribute("condition", 'email')
        phone.setAttribute("condition", 'phone')

        submit.onclick = ()=>{
            let validator = new builder.Validator(fields, (item, type)=>{
                item.style.outline = "2px solid var(--fullRed)"
            },false, {
                email: (email)=>{return (/^[a-zA-Z0-9._-]+@[a-z]+\.[a-z]+$/).test(email)},
                phone: (phone)=>{return (/^0[5678]\d{8}$/).test(phone)},
            })

            if (validator.validate()){
                const query = `
        mutation SubmitEmail($name: String!, $email: String!, $phone: String!) {
            submitEmail(name: $name, email: $email, phone: $phone)
        }
    `;
                const variables = {
                    name: name.value,
                    email: email.value,
                    phone: phone.value
                }
                builder.brdige("https://vicles.com/api/", "POST", JSON.stringify({query, variables}), (data, status)=>{
                    data = JSON.parse(data);
                    if (data.data.submitEmail)
                    {
                        close.click()
                        Swal.fire({
                            title: translate.ThankYou[builder.prefs.get('language')],
                            text: translate.ThankYouMessage[builder.prefs.get('language')],
                            icon: "success"
                        });
                    }
                    else
                    {
                        Swal.fire({
                            title: translate.Error[builder.prefs.get('language')],
                            text: translate.ErrorMessage[builder.prefs.get('language')],
                            icon: "warning"
                        });
                    }
                }, (error, status)=>{
                });
            }
        }
        this.component = builder.block(null, 'vicles_register_panel', [background, window]);
    }
}