import * as builder from '../vendors/builder.js';
import translate from '../../translate/landing.json' with { type: "json" };
import Register from "./register.js";


export default class Header extends builder.Component {

    parent;

    constructor(parent) {
        super();
        this.parent = parent
        this.create()
    }
    #createLanguageMenu()
    {
        let french = {value: 'fr',  text:"Français"},
            english = {value: 'en',  text:"English"},
            arabic = {value: 'ar',  text:"عربية"};

        return [french, english, arabic]
    }

    #langDropdownAdapter(item)
    {
        let lang = builder.label('languagesMenu', item.text),
            container = builder.block(null, 'dropDownItem', [lang]);

        if (builder.isArabic(item.text))
            lang.style.direction = 'rtl';

        container.setAttribute('itemValue', item.value);
        return container;
    }

    create() {
        let logo = builder.label('vicles_logo', "Vicles"),
            langDrop = new builder.Dropdown(null, 'vicles_drop_langs', '<i class="far fa-sort-down"></i>', this.#createLanguageMenu(), this.#langDropdownAdapter),
            globe = builder.button(null, 'vicles_globe', null, '<i class="ri-earth-line"></i>'),
            languages = builder.block(null, 'vicles_languages', [globe, langDrop.getHTML()]),
            navbar = builder.block(null, 'vicles_navbar', [logo, languages]),
            notif = builder.image(null, 'vicles_notif', 'assets/notification.png'),
            trapez = builder.image(null, 'vicles_trapez', 'assets/trapez.svg'),
            circles = builder.image(null, 'vicles_circles', 'assets/car_top_view.png'),
            circle_container = builder.block(null, 'vicles_circle_container', [circles]),
            square =builder.image(null, 'vicles_square', 'assets/square.png'),
            triangle = builder.image(null, 'vicles_triangle', 'assets/triangle.png'),
            cursor = builder.image(null, 'vicles_cursor', 'assets/cursor_square.png'),
            trap_circ = builder.block(null,'vicles_trapcirc', [trapez, circle_container, notif]),
            Hook = builder.heading(1, 'vicles_hook', translate.Hook[builder.prefs.get('language')]),
            hookMessage = builder.label('vicles_hookMessage', translate.hookMessage[builder.prefs.get('language')]),
            joinUS = builder.button(null, 'vicles_join_us', translate.joinUS[builder.prefs.get('language')]),
            position_block_hook = builder.block(null, 'vicles_position_block_hook', [Hook, hookMessage, joinUS]),
            HookBlock = builder.block(null, 'vicles_hero', [position_block_hook])

        langDrop.onChange = (value)=>{
            let prefs = new builder.Preferences();

            prefs.add('language', value)
            this.parent.rerender();
        }

        langDrop.setValue(builder.prefs.get("language"))

        joinUS.onclick = ()=>{
            builder.app.append((new Register()).getHTML())
        }

        this.component = builder.block(null, 'vicles_header', [navbar, trap_circ, square, triangle, cursor, HookBlock])
    }
}